<template>
  <li
      class="nav-item"
      :class="{
      'active': isActive,
      'disabled': item.disabled
    }"

      v-permission="item.permissions"
  >
    <b-link
        v-bind="linkProps"
        class="d-flex align-items-center"
    >
      <component v-bind:is="item.icon" :class="item.icon"></component>
      <!-- <feather-icon :icon="item.icon || 'CircleIcon'" /> -->
      <span class="menu-title text-truncate text-reg-16">{{ t('nav.' + item.title) }} </span>
      <b-badge
          v-if="item.tag"
          pill
          :variant="item.tagVariant || 'primary'"
          class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import {useUtils as useI18nUtils} from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {isActive, linkProps, updateIsActive} = useVerticalNavMenuLink(props.item)
    const {t} = useI18nUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,


      // i18n
      t,
    }
  },

}
</script>
