import i18n from '@/libs/i18n';

/* eslint-disable */
// prettier-ignore
export default {
    pages: {
        key: 'title',
        data: [

            {
                title: i18n.t('nav.dashboard'),
                icon: 'home-icon',
                route: {name:'dashboard'},
            },
            {
                title: i18n.t('nav.orders'),
                icon: 'orders-icon',
                route: {name:'orders'},
            },
            {
                title: i18n.t('nav.chat'),
                icon: 'nav-chat-icon',
                route: {name:'chat'},
            },
            {
                title: i18n.t('nav.reports'),
                icon: 'nav-reports-icon',
                route: {name:'reports'},
            },
            {
                title: i18n.t('nav.ads'),
                icon: 'nav-ads-icon',
                route: {name:'ads'},
            },
            {
                title: i18n.t('nav.users.clients'),
                icon: 'nav-other-icon',
                route: {name:'users-clients'},
            },
            {
                title: i18n.t('nav.users.vendors'),
                icon: 'nav-other-icon',
                route: {name:'users-vendors'},
            },
            {
                title: i18n.t('nav.users.drivers'),
                icon: 'nav-other-icon',
                route: {name:'users-drivers'},
            },


            {

                title: i18n.t('nav.financial.title'),
                icon: 'money-icon',
                route: {name:'financial-all'},

            },

            {
                title: i18n.t('nav.discounts'),
                icon: 'coupons-icon',
                route: {name:'discounts'},
            },


            {
                title: i18n.t('nav.reviews'),
                icon: 'star-icon',
                route: {name:'reviews'},
            },
            {
                title: i18n.t('nav.settings'),
                icon: 'setting-icon',
                route: {name:'settings'},
            },

        ],
    },


}
/* eslint-enable */
