<template>
  <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media mx-4"
      right
      v-if="latestNotifications"
      @show="showData"
  >
    <template #button-content>
      <li class="nav-item">
        <span class="position-relative">
          <notification2-icon/>

          <span v-if="newNotifications.length !== 0" class="custom-notification-badge">
            {{ newNotifications.length }}
          </span>

        </span>
      </li>

    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex align-items-center">
        <h4 class="notification-title mb-0 mr-auto text-med-16 text-main-font">
          {{ $t('common.notifications') }}
        </h4>

        <b-badge pill variant="light-main"
                 class="notification-badge text-reg-9">
          <span>{{ newNotifications.length }} {{ $t('common.new') }}</span>
        </b-badge>
      </div>
    </li>

       <!-- Notifications -->
    <div
        class="scrollable-container media-list scroll-area"
        style="max-height: 500px;overflow-y: scroll"

        :key="key"
    >
      <!-- Account Notification -->

      <!-- System Notifications -->
      <b-link
          v-for="notification in latestNotifications"
          :key="notification.id"
          :to="{ name: 'notification' }"
          class="notification"
      >
        <b-media>
          <template #aside>
            <b-avatar
                size="34"
                class="avatar mx-2"
                :class=" notification.is_read === 0 ? 'is-not-read' : 'is-read'"
            >
              <notification2-icon class="size-16"/>
            </b-avatar>
          </template>
          <p class="media-heading d-flex  align-items-center" style="gap: 8px">
            <span class="text-med-14 text-font-main">
              {{ notification.title }}
            </span>

            <span class="text-reg-12 text-font-sub">
              {{ formattedDate(notification.created_at) }}
            </span>
          </p>

          <small class="text-med-11 text-font-secondary">
            {{ notification.content }}
          </small>
        </b-media>
      </b-link>
    </div>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-dropdown-item
          @click="goToNotifications"
          block
          type="submit"
          variant="main"
          link-class="btn  bg-main text-reg-14 text-white justify-content-center align-items-center  rounded-10 "
      >
        {{ $t('common.show_notifications') }}
      </b-dropdown-item>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {BAvatar, BBadge, BLink, BMedia, BNavItemDropdown,} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';
import {mapGetters} from 'vuex';

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,

  },
  data(){
    return {
      key:1,
    }
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      latestNotifications: 'admin/notifications/getLimit',
    }),

    newNotifications() {
      return this.latestNotifications.filter(
          notification => notification.is_read === 0,
      );
    },
  },


  methods: {
    showData(){
      this.key++;
      console.log('asdfasdf')
    },
    goToNotifications() {
      this.$router.push({name: 'notification'});
    },
    formattedDate(date) {
      return this.moment().diff(this.moment(date), 'days') >= 7
          ? this.moment(date).format('YYYY-MM-DD')
          : this.moment(date).fromNow(true);
    },

  },
};
</script>

<style>
</style>
